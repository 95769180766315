.burger-menu {
    position: fixed;
    @include flexColumn(space-between, flex-start);
    max-width: 475px;
    width: 100%;
    height: 100%;
    left: 90px;
    transform: translateX(-130%);
    padding: 55px 20px 55px 0;
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
    box-shadow: 10px 0 36px 0 rgba(0, 0, 0, 0.09);
    background-color: $color-light-opacity-05;
    transition: 0.3s;
    &.open {
        transform: translateX(0);
        @include media-desktop-max {
            display: flex;
            transition: 0.3s;
        }
    }
    @include media-tablet {
        height: calc(100% - 60px);
        top: 60px;
        left: unset;
        right: 0;
        transform: translateX(110%);
    }
    @include media-mobile {
        padding: 20px 20px 30px 0;
    }
    @include media-desktop-max {
        display: none;
        left: calc(50% - 1190px);
        transform: translateX(0);
    }
}
.burger-menu__wrapper {
    width: 100%;
    height: 100%;
    padding-left: 70px;
    @include flexColumn;
    overflow: auto;
    overscroll-behavior: none;
    @include media-mobile {
        padding-left: 26px;
        gap: 10px;
    }
}
.burger-menu__btn {
    position: absolute;
    @include flexColumn(center, center);
    left: -90px;
    top: 0;
    width: 90px;
    height: 60px;
    padding: 0;
    border: 0;
    background-color: $color-primary-active;
    @include media-tablet {
        left: unset;
        top: -60px;
        right: 5vw;
    }
    @include media-mobile {
        right: 15px;
    }
    @include media-mobile-xs {
        right: 0;
        width: 75px;
    }
}
.burger-menu__btn__icon {
    &::before {
        content: "";
        display: block;
        position: relative;
        height: 2px;
        border-radius: 3px;
        background-color: $color-bg-figure2;
        transition: 0.2s;
        transform: rotate(-90deg);
    }
    position: relative;
    width: 24px;
    height: 2px;
    border-radius: 3px;
    background-color: $color-bg-figure2;
    transition: 0.2s;
    transform: rotate(45deg);
}
.burger-menu__nav {
}
.burger-menu__top-block {
    height: 100%;
    min-height: 500px;
    @include flexColumn;
    @include media-mobile {
        min-height: 370px;
    }
}
.nav {
    height: 50%;
    max-height: 400px;
    min-height: 175px;
    margin-bottom: 32px;
}
.nav__items {
    height: 100%;
    @include flexColumn(space-between, flex-start);
}
.nav__item {
    transition: 0.3s;
    &:hover {
        transform: translateX(30px);
        color: $color-primary;
    }
}
.nav__link {
    @include font(24px, 300, $color-dark-light, normal);
    text-decoration: none;
    transition: 0.3s;
    &:hover {
        color: $color-primary;
    }
    &:active {
        color: $color-primary-active;
    }
}
.burger-menu__phone {
    @include font(32px, 600, $color-primary, normal);
    margin-bottom: 5vh;
    text-decoration: none;
    transition: 0.3s;
    &:active {
        color: $color-primary-active;
    }
}
.burger-menu__address-block {
    @include flexColumn(flex-start, flex-start);
    gap: 25px;
    @include media-mobile {
        max-width: 275px;
    }
}
.burger-menu__address-icon-svg {
    min-width: 24px;
    color: $color-primary;
    // &:active {
    //     color: $color-primary-active;
    // }
}
.burger-menu__address-link {
    @include flexRow(flex-start, center);
    gap: 10px;
    @include font(16px, 500, $color-dark, normal);
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: $color-primary;
    }
    &:active {
        color: $color-primary-active;
        .burger-menu__address-icon-svg {
            color: $color-primary-active;
        }
    }
}

.burger-menu__bottom-block {
    width: 100%;
    height: 23px;
    padding-bottom: 24px;
    @include flexColumn(space-between, flex-start);
}
.burger-menu__icon-block {
    width: 100%;
    @include flexRow(flex-start, center);
    gap: 40px;
}
.burger-menu__icon-link {
    color: $color-bg-figure2;
    transition: 0.2s;
    &:hover {
        color: $color-dark-light;
    }
    &:active {
        color: $color-primary-active;
    }
}
.burger-menu__close-btn {
    padding: 0;
    padding-left: 70px;
    // padding-top: 33px;
    margin-top: 33px;
    @include flexRow(flex-start, baseline);
    gap: 15px;
    @include font(16px, 300, $color-primary, normal);
    border: 0;
    background-color: unset;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        color: $color-primary-active;
    }
    @include media-mobile {
        margin-top: 20px;
        padding-left: 26px;
    }
}
.burger-menu__close-btn-svg {
    @include media-tablet {
        transform: rotate(180deg);
    }
    @include media-mobile-xs {
        width: 140px;
    }
}
