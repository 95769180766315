.hero {
    @include flexColumn(center, flex-start);
    width: 100%;
    height: 100vh;
    position: relative;
    // background: url("../assets/img/implants.jpg") no-repeat right top / contain;
    @include media-vertical {
        height: auto;
        padding-left: 5%;
    }
    @include media-tablet {
        height: 100vh;
        padding: 0;
        padding-left: 5%;
    }
    @include media-mobile {
        height: 617px;
        padding-left: 0;
        padding-bottom: 35px;
    }
    @include media-mobile-xs {
        height: 440px;
    }
}
.hero__wrapper {
    @include flexColumn(center, flex-start);
    position: relative;
    width: 100%;
    height: 100%;
    @include media-vertical {
        height: auto;
        padding: 64px 0;
    }
    @include media-tablet {
        @include flexColumn;
        max-height: 752px;
        height: 752px;
        padding: 0;
        justify-content: flex-start;
    }
    @include media-mobile {
        height: 100%;
        max-height: unset;
    }
}
.hero__video {
    position: absolute;
    width: calc(100vw - 30%);
    height: 100vh;
    right: 0;
    top: 0;
    background-color: $color-parallax-bg;
    @include media-vertical {
        height: 100%;
    }
    @include media-desktop-max {
        max-width: 1792px;
        margin: 0 auto;
    }
    @include media-tablet {
        width: calc(100vw - 20%);
        max-height: 611px;
        top: 100px;
    }
    @include media-mobile {
        width: 100vw;
        // max-height: 108vw;
        max-height: 400px;
        top: 0;
    }
    @include media-mobile-xs {
        max-height: 200px;
    }
}
.hero__parallax-container {
    position: absolute;
    @include media-tablet {
        max-height: 600px;
        height: 100%;
        width: 95%;
        top: 100px;
        right: 0;
    }
    @include media-mobile {
        width: 100vw;
        max-height: none;
        top: 0;
    }
}
.hero__parallax-wrapper {
    position: fixed;
    width: calc(100vw - 105px);
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 1;
    @include media-desktop-max {
        width: 2347px;
        right: unset;
        border-right: 1px solid $color-dark-light;
    }
    @include media-tablet {
        position: sticky;
        width: unset;
        height: unset;
    }
    @include media-mobile {
        position: fixed;
        height: 100%;
        width: 100vw;
        margin-top: 60px;
        max-height: 400px;
    }
}
.hero__parallax-block {
    @include media-mobile {
        position: relative;
        width: 100%;
        height: 400px;
    }
    @include media-mobile-xs {
        height: 200px;
    }
}
.hero__parallax-mask {
    width: 100%;
    height: 100vh;
    position: absolute;
    -webkit-mask-image: url("../assets/svg/4.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: right top;
    -webkit-mask-size: auto 100%;
    mask-image: url("../assets/svg/4.svg");
    mask-repeat: no-repeat;
    mask-position: right top;
    mask-size: auto 100%;
    @supports (mask-image: url("../assets/svg/4.svg")) or (-webkit-mask-image: url("../assets/svg/4.svg")) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
    @include media-vertical {
        max-width: 1095px;
        right: 0;
        mask-size: 1650px auto;
    }
    @include media-tablet {
        mask-size: auto 611px;
    }
    @include media-mobile {
        mask-size: auto 400px;
        width: 100vw;
        -webkit-mask-image: url("../assets/svg/4-mobile-center.svg");
        mask-image: url("../assets/svg/4-mobile-center.svg");
        -webkit-mask-position: center top;
        mask-position: center top;
        @supports (mask-image: url("../assets/svg/4-mobile-center.svg")) or
            (-webkit-mask-image: url("../assets/svg/4-mobile-center.svg")) {
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
    @include media-mobile-xs {
        mask-size: auto 200px;
    }
}

.hero__parallax {
    @include flexRow(flex-end);
    width: 100%;
    opacity: 0.74;

    @include media-vertical {
        // background: url("../assets/svg/4.svg") no-repeat right top/150% auto;
    }
    @include media-tablet {
        position: absolute;
        // width: unset;
    }
    @include media-mobile {
        justify-content: center;
        overflow: hidden;
    }
}
.hero__parallax-img {
    height: 100vh;
    @include media-vertical {
        max-width: 1650px;
        height: auto;
    }
    @include media-tablet {
        height: 611px;
        width: unset;
    }
    @include media-mobile {
        width: auto;
        height: auto;
        max-height: 400px;
    }
    @include media-mobile-xs {
        max-height: 200px;
    }
}
.hero__parallax-bottom {
    height: 300px;
    opacity: 0.74;
    background-color: $color-light;
    @include media-tablet {
        position: absolute;
        width: 100%;
        top: 611px;
        z-index: 2;
    }
}
.hero__text {
    @include flexColumn(space-between, flex-start);
    position: relative;
    width: 453px;
    height: 624px;

    z-index: 2;
    @include media-mobile {
        justify-content: flex-end;
        gap: 20px;
        width: auto;
        // height: 100vh;
        height: auto;
        padding: 0 15px;
    }
}
.hero__title {
    @include font(34px, 600, $color-dark);
    margin-bottom: 15px;
    @include media-mobile {
        font-size: 30px;
    }
    @include media-mobile-xs {
        font-size: 26px;
    }
}
.hero__title-desc {
    @include font(24px, 300, $color-dark);
    @include media-mobile-xs {
        font-size: 20px;
    }
}
.hero__btn-container {
}

.hero__achievement {
    @include font(24px, 300, $color-dark-light);
    @include media-mobile {
        display: none;
    }
}
.hero__achievement-title {
    display: block;
    @include font(34px, 800, $color-primary);
}
.hero__btn {
    display: inline-block;
    @include media-mobile-xs {
        padding: 10px;
    }
}
