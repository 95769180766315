.phone-btn {
    @include flexColumn(flex-start, center);
    gap: 2px;
    margin-bottom: 12px;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    &:hover {
        .phone-btn__icon {
            animation-play-state: paused;
        }
    }
}
.phone-btn__icon {
    animation: phoneBtnAnimation 1.5s cubic-bezier(1, 0, 0, 1) infinite;
}
.phone-btn__text {
    @include font(15px, 400, $color-dark);
}

@keyframes phoneBtnAnimation {
    0% {
        transform: rotate(-10deg) translateX(-1px);
    }
    50% {
        transform: rotate(10deg) translateX(1px);
    }

    100% {
        transform: rotate(-10deg) translateX(-1px);
    }
}
