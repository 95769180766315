.faq {
    z-index: 2;
    position: relative;
    background-color: $color-light;
    @include media-desktop-b {
        @include flexColumn(flex-start, center);
        padding-left: 0;
    }
    @include media-mobile {
        padding: 0 15px;
    }
}

.faq__title {
    padding: 50px 0 70px;
    background-color: $color-light;
    @include media-tablet {
        max-width: 650px;
    }
    @include media-mobile {
        padding: 0 0 70px;
    }
}
.faq__questions-container {
    position: relative;
    @include flexColumn();
    gap: 32px;
}
.faq__question-container {
}
.faq__question {
    @include flexRow(flex-start, center);
    gap: 10px;
    cursor: pointer;
    @include font(24px, 400, $color-dark, normal);
    transition: 0.2s;
    &.active {
        color: $color-accent;
        .faq__question-icon {
            transform: rotate(45deg);
            .faq__question-icon-element-1 {
                background-color: $color-accent;
            }
            .faq__question-icon-element-2 {
                background-color: $color-accent;
            }
        }
    }
    @include media-mobile {
        font-size: 18px;
    }
}
.faq__question-icon {
    position: relative;
    min-width: 40px;
    min-height: 40px;
    transition: 0.2s;
}
.faq__question-icon-element-1 {
    position: absolute;
    width: 40px;
    height: 1px;
    top: 50%;
    left: 0;
    transition: 0.2s;
    background-color: $color-dark;
}
.faq__question-icon-element-2 {
    position: absolute;
    width: 1px;
    height: 40px;
    top: 0;
    left: 50%;
    transition: 0.2s;
    background-color: $color-dark;
}
.faq__question-text {
}
.faq__answer-wrapper {
    max-width: 977px;
    max-height: 0;
    margin: 0 50px;
    border-radius: 12px;
    background: $color-light;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    @include font(16px, 400, $color-dark, normal);
    transition: 0.3s;
    &.active {
        max-height: 100vh;
        margin: 30px 50px;
        @include media-mobile {
            margin: 20px 0 0;
        }
    }
    @include media-mobile {
        margin: 0;
    }
}
.faq__answer {
    padding: 20px;
    p {
        margin-bottom: 0.5em;
    }
}
.faq__list {
    margin: 0.5em 0;
    list-style: disc inside;
    li {
        margin-bottom: 0.25em;
    }
}
