.before-after {
    // scroll-margin-top: 60px;
    position: relative;
    padding-top: 50px;
    padding-bottom: 120px;
    z-index: 3;
    overflow: hidden;
    @include media-tablet {
        padding-top: 60px;
        padding-right: 5%;
    }
    @include media-desktop {
        padding-top: 50px;
        padding-right: 5%;
        background-color: $color-light;
    }
    @include media-mobile {
        @include flexColumn(flex-start, center);
        padding: 0 15px 40px;
    }
}
.before-after__title {
    margin-bottom: 80px;
    @include media-tablet {
        margin-bottom: 50px;
    }
}
.before-after__wrapper {
    @include flexRow();
    gap: 40px;
    @include media-tablet {
        @include flexColumn();
        gap: 100px;
        max-width: 600px;
    }
    @include media-desktop {
        justify-content: center;
    }
    @include media-mobile {
        max-width: 570px;
    }
}
.before-after__slider-block {
    position: relative;
    @include media-mobile {
        margin: 0 -15px;
    }
}
.before-after__slider {
    min-height: 350px;
    .baSlider__img {
        min-height: 350px;
    }
    @include media-tablet {
        min-height: 0;
        .baSlider__img {
            min-height: 0;
        }
    }
}
.before-after__img-titles {
    position: absolute;
    width: 100%;
    @include flexRow(space-between);
    // gap: 33px;
    padding-top: 40px;
    margin-bottom: 20px;
    @include media-tablet {
        width: 95%;
    }
    @include media-mobile {
        width: 90%;
        margin: 0 15px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.before-after__desc {
    @include flexColumn(center, flex-start);
    max-width: 400px;
    @include media-tablet {
        max-width: unset;
    }
    @include media-desktop {
        max-width: unset;
        width: 40%;
        margin-left: 5vw;
    }
}
.before-after__stages {
    @include media-tablet {
        @include flexRow;
        gap: 50px;
    }
    @include media-mobile {
        @include flexColumn;
        gap: 20px;
    }
}
.before-after__stage {
    @include media-tablet {
        width: 50%;
    }
    @include media-mobile {
        width: unset;
    }
}
.before-after__case-title {
    margin-bottom: 5px;
    @include font(16px, 500, $color-dark, normal);
    @include media-tablet {
        display: none;
    }
}
.before-after__stage-title {
    @include font(32px, 300, $color-dark, normal);
    @include media-mobile {
        font-size: 24px;
    }
}
.before-after__stage-desc {
    @include font(16px, 300, $color-dark, normal);
    margin-bottom: 25px;
    &_after {
        margin-bottom: 43px;
    }
    @include media-mobile {
        margin-bottom: 0;
        &_after {
            margin-bottom: 43px;
        }
    }
}
