.btn {
    padding: 15px 26px;
    @include font(16px, 300);
    border: 2px solid $color-accent;
    border-radius: 9px;
    background-color: transparent;
    transition: 0.2s;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
}
.btn_dark {
    color: $color-light;
    background-color: $color-accent;
    &:hover {
        border-color: $color-primary;
        background-color: $color-primary;
    }
    &:active {
        border-color: $color-primary-active;
        background-color: $color-primary-active;
    }
}
.btn_light {
    color: $color-accent;
    &:hover {
        color: $color-light;
        border-color: $color-accent;
        background-color: $color-accent;
    }
    &:active {
        color: $color-accent;
        border-color: $color-accent;
        background-color: transparent;
    }
}
.btn-text {
    @include font(16px, 500, $color-dark-light, normal);
    cursor: pointer;
    border: 0;
    background-color: $color-light;
    transition: 0.2s;
    &.active {
        color: $color-accent;
        text-decoration: none;
        cursor: unset;
    }
    &:hover {
        color: $color-accent;
        text-decoration: none;
    }
    &:active {
        color: $color-accent;
        text-decoration: none;
    }
}
.btn-text_nbsp {
    white-space: nowrap;
}
