.price {
    @include flexRow();
    height: 766px;
    padding: 96px 0 0;
    gap: 1px;
    overflow: auto;
    @supports not (selector(&::-webkit-scrollbar-button) and selector(&::-webkit-scrollbar-track)) {
        scrollbar-width: thin;
    }

    &::-webkit-scrollbar-track {
        background: $color-dark-light;
        border-radius: 50px;
    }
    &::-webkit-scrollbar {
        width: 100%;
        height: 9px;
    }
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: $color-light;
        border-radius: 50px;
        border: 3px solid $color-dark-light;
    }
    &::-webkit-scrollbar-button:end:increment {
        width: 10%;
        display: block;
        background: transparent;
    }
    &::-webkit-scrollbar-button:start:decrement {
        width: 5%;
        display: block;
        background: transparent;
    }
    @include media-mobile {
        height: auto;
        @include flexColumn;
    }
}
.price__offer {
    @include flexColumn(space-between, center);
    min-width: 400px;
    width: 100%;
    @include media-tablet {
        min-width: 360px;
        background-color: $color-primary;
    }
    padding-bottom: 5px;
    @include media-mobile {
        padding-bottom: 5px;
    }
    @include media-mobile-xs {
        min-width: 320px;
    }
}
.price__offer-img-block {
    @include flexColumn(center, center);
    width: 100%;
    min-height: 233px;
    background-color: $color-light;
    padding-bottom: 30px;
}
.price__offer-img {
}
.price__offer-text-container {
    position: relative;
    @include flexColumn;
    width: 100%;
    height: 100%;
    top: -30px;
    background-color: rgb(255 255 255 / 80%);

    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    @include media-mobile {
        position: static;
        margin-top: -30px;
    }
}
.price__offer-text-container-shadow {
    @include flexColumn(space-around, center);
    width: 100%;
    height: 100%;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    @include media-mobile {
        align-items: flex-start;
        padding: 0 15px 34px 15px;
        gap: 28px;
    }
}
.price__offer-text {
    max-width: 300px;
    @include media-mobile {
        padding: 30px 0;
    }
}
.price__offer-text-dark {
    @include font(34px, 300, $color-dark, normal);
}
.price__offer-text-accent {
    @include font(34px, 800, $color-primary, normal);
}
.price__offer-text-small {
    @include font(16px, 300, $color-dark, normal);
}
.price__offer-old-price {
    @include font(20px, 400, $color-accent, normal);
    text-decoration: line-through;
}
.price__offer-text-digits {
    @include font(24px, 800, $color-dark, normal);
}
.price__offer-text-rub {
    @include font(24px, 300, $color-dark, normal);
}
.price__offer-perks-container {
    @include flexColumn;
    padding: 25px 0;
    gap: 5px;
}
.price__offer-perks {
    @include font(16px, 500, $color-dark, normal);
}
.price__offer-perks-accent {
    color: #ff0000;
    font-weight: 500;
}
.price__offer-button {
}
