.our-patients {
    @include flexRow(space-between, flex-start);
    position: relative;
    max-width: 100%;
    height: 720px;
    padding: 90px 5% 0 5%;
    z-index: 2;
    background-color: $color-bg-dark;
    scroll-margin-top: 0;
    @include media-tablet {
        padding: 157px 5% 36px 5%;
        height: auto;
        scroll-margin-top: 60px;
    }
    @include media-desktop {
        padding-top: 0;
        align-items: center;
    }
    @include media-desktop-b {
        justify-content: space-around;
    }
    @include media-mobile {
        justify-content: center;
        padding: 38px 15px 48px 15px;
    }
}
.our-patients__left-block {
    @include flexColumn(center, flex-start);
    gap: 10px;
    @include media-desktop {
        height: 100%;
    }
}

.our-patients__right-block {
    @include flexColumn(center, center);
    gap: 10px;
    @include media-desktop {
        height: 100%;
        justify-content: space-evenly;
        gap: unset;
    }
    @include media-mobile {
        display: none;
    }
}

.our-patients__title {
    max-width: 385px;
    width: 100%;
    @include media-tablet {
        position: absolute;
        max-width: unset;
        width: 90%;
        top: 54px;
        text-align: center;
    }
    @include media-mobile {
        position: static;
        width: auto;
        text-align: left;
    }
}
.our-patients__text-container {
    @include flexColumn(space-between, flex-start);
    gap: 40px;
    max-width: 400px;
    width: 100%;
    @include media-tablet {
        max-width: 294px;
        height: 520px;
    }
    @include media-desktop {
        max-width: 567px;
    }
    @include media-mobile {
        max-width: unset;
        height: 100%;
    }
}
.our-patients__text {
    margin-left: 30px;
    @include font(16px, 400, $color-dark);
    position: relative;
    transition: 0.2s;
    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 18px;
        height: 17px;
        top: 2px;
        left: -30px;
        transition: 0.3s;
        background: url("../assets/svg/tooth-small.svg") no-repeat;
    }
}
.our-patients__slider {
}
.our-patients__patient-img {
    width: 370px;
    height: 520px;
    @include media-desktop {
        width: 436px;
        height: 614px;
    }
}
.our-patients__link {
    padding-left: 15px;

    position: relative;
    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none;
    }
    @include font(14px, 300, $color-dark-light);
    transition: 0.2s;
    @include media-desktop {
        padding-left: 0;
    }
    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 21px;
        height: 13px;
        top: 0;
        left: -15px;
        transition: 0.3s;
        background: url("../assets/svg/show-more.svg") no-repeat;
        @include media-desktop {
            left: -30px;
        }
    }
    &:hover {
        cursor: pointer;
        color: $color-accent;
        &::before {
            width: 21px;
            height: 14px;
            background: url("../assets/svg/show-more-hover.svg") no-repeat;
            opacity: 1;
        }
    }
}
