.header {
    @include flexColumn(space-between, center);
    position: fixed;
    top: 0;
    left: 0;
    width: 90px;
    height: 100vh;
    z-index: 100;
    background-color: $color-secondary;
    @include media-desktop-max {
        left: unset;
    }
    @include media-tablet {
        @include flexRow(space-between, center);
        width: 100%;
        height: 60px;
        padding: 0 5%;
        background-color: $color-primary;
    }
    @include media-mobile {
        padding: 0 15px;
    }
    @include media-mobile-xs {
        padding-right: 0;
    }
}
.header__burger-btn {
}

.header__logo {
    @include media-mobile {
        display: none;
    }
    @include media-tablet {
        height: 53px;
        padding-right: 15px;
    }
}
.header__logo-svg {
    @include media-tablet {
        width: 155px;
    }
}
.header__logo-text {
    margin-top: 23px;
    margin-bottom: auto;

    @include media-tablet {
        height: 39px;
        margin: unset;
        margin-right: auto;
    }
    @include media-mobile {
        height: 36px;
    }
}
.header__phone {
    @include flexColumn(flex-end, center);
    height: 91px;
    width: 100%;

    background-image: url("../assets/svg/header-phone-bg.svg");
    background-size: cover;
    background-position: center;
    @include media-tablet {
        width: 90px;
        height: 60px;
        background-image: none;
        background-color: $color-secondary;
    }
    @include media-mobile-xs {
        width: 70px;
    }
}
