@font-face {
    font-family: "Exo 2";
    src:
        local("Exo 2"),
        url("../assets/fonts/Exo2-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Exo 2";
    src:
        local("Exo 2"),
        url("../assets/fonts/Exo2-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Exo 2";
    src:
        local("Exo 2"),
        url("../assets/fonts/Exo2-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Exo 2";
    src:
        local("Exo 2"),
        url("../assets/fonts/Exo2-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Exo 2";
    src:
        local("Exo 2"),
        url("../assets/fonts/Exo2-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Exo 2";
    src:
        local("Exo 2"),
        url("../assets/fonts/Exo2-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: block;
}
