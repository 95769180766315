.divider {
    @include flexRow;
    position: relative;
    width: 100%;
    z-index: 2;
}
.divider__part-left {
    width: 30%;
    height: 9px;
    background-color: $color-primary-active;
}
.divider__part-right {
    width: 70%;
    height: 9px;
    background-color: $color-primary;
}
