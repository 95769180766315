.our-team {
    padding-top: 50px;
}
.our-team__title {
    margin-bottom: 65px;
}

.our-team__cards-wrapper {
    @include flexRow();
    flex-wrap: nowrap;
    gap: 20px;
    padding-bottom: 30px;
    overflow: auto;

    @supports not (selector(&::-webkit-scrollbar-button) and selector(&::-webkit-scrollbar-track)) {
        scrollbar-width: thin;
    }
    &::-webkit-scrollbar-track {
        background: $color-accent;
        border-radius: 50px;
    }
    &::-webkit-scrollbar {
        width: 100%;
        height: 9px;
    }
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: $color-light;
        border-radius: 50px;
        border: 3px solid $color-accent;
    }
    &::-webkit-scrollbar-button:end:increment {
        width: 10%;
        display: block;
        background: transparent;
    }
}
.doctor-card {
    max-width: 322px;
    min-width: 322px;
    max-height: 508px;
    cursor: pointer;
    background-color: $color-light;
    position: relative;
    transition: 0.2s;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.2s;
        background-color: $color-light;
    }
    &::after {
        content: "узнать больше";
        position: absolute;

        top: calc(50% - 25px);
        left: calc(50% - 85px);
        padding: 19px 30px;
        opacity: 0;
        transition: 0.2s;
        background-color: $color-accent-opacity-08;
        border-radius: 9px;
        box-shadow: 0px 0px 20px 0px $color-accent;
        -webkit-backdrop-filter: blur(4.5px);
        backdrop-filter: blur(4.5px);
        @include font(16px, 500, $color-light, normal);
    }
    @include media-no-touch {
        &:hover {
            filter: drop-shadow(0px 0px 30px rgba(0, 133, 255, 0.2));
            &::before {
                opacity: 0.8;
            }
            &::after {
                opacity: 1;
            }
        }
    }
    &.active {
        &:hover {
            filter: none;
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 0;
            }
        }
        .doctor-card__info {
            // opacity: 1;
            left: 0;
        }
    }

    @include media-mobile-xs {
        max-width: 90vw;
        min-width: 90vw;
    }
}
.doctor-card__name {
    padding: 18px 23px;
    @include font(24px, 500, $color-dark, normal);
    word-spacing: 100vw;
    @include media-mobile-xs {
        font-size: 20px;
    }
}
.doctor-card__name-desc {
    padding: 0 23px;
    @include font(16px, 300, $color-dark, normal);
    strong {
        font-weight: 400;
    }
    @include media-mobile-xs {
        font-size: 14px;
    }
}
.doctor-card__name-desc-more {
    @include font(16px, 300, $color-dark-light, normal);
}
.doctor-card__doctor-title {
    display: block;
    margin-bottom: 8px;
    @include font(16px, 500, $color-dark, normal);
}
.doctor-card__photo {
    max-width: 322px;
    min-width: 322px;
    @include media-mobile-xs {
        max-width: 90vw;
        max-height: 45vh;
    }
}
.doctor-card__info {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    padding: 23px;
    @include font(16px, 300, $color-light, normal);
    background-color: $color-accent;
    overflow: auto;
    scrollbar-width: thin;
    transition: 0.2s;
    strong {
        font-weight: 600;
    }
}
.doctor-card__info-name {
    margin-bottom: 15px;
    @include font(24px, 500, $color-light, normal);

    @include media-mobile-xs {
        font-size: 20px;
    }
}
.doctor-card__info-doctor-title {
    display: block;
    @include font(16px, 500, $color-light, normal);
}
.doctor-card__info-text {
    margin: 8px 0;
}
.doctor-card__info-list {
    padding-left: 18px;
    list-style-type: disc;
}
