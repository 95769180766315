.why-recommend {
    position: relative;
    width: 100%;
    top: 0;
    scroll-margin-top: 0;
    @include media-tablet {
        scroll-margin-top: 60px;
    }
    @include media-mobile {
        order: 0;
        padding: 0 15px;
    }
}

.why-recommend__parallax-wrapper {
    position: sticky;
    width: 100%;
    // height: 100vh;
    top: 0;
    right: 0;
    z-index: 1;
}
.why-recommend__parallax-mask {
    width: 100%;
    height: max(850px, (100vw/2) + 180px);
    position: absolute;
    -webkit-mask-image: url("../assets/svg/4-2.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: right top;
    -webkit-mask-size: auto 100%;
    mask-image: url("../assets/svg/4-2.svg");
    mask-repeat: no-repeat;
    mask-position: right top;
    mask-size: auto max(850px, (100vw/2) + 180px);
    @supports (mask-image: url("../assets/svg/4-2.svg")) or (-webkit-mask-image: url("../assets/svg/4-2.svg")) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
    background: linear-gradient(to right, rgb(255 255 255 / 70%), rgb(255 255 255 / 0), rgb(255 255 255 / 30%));
    @include media-tablet {
        -webkit-mask-size: auto max(750px, 50vw + 180px);
        mask-size: auto max(750px, 50vw + 180px);
    }
    @include media-desktop {
        height: max(1080px, (100vw/2) + 180px);
        -webkit-mask-size: auto max(1080px, (100vw/2) + 180px);
        mask-size: auto max(1080px, (100vw/2) + 180px);
    }
    @include media-desktop-max {
        height: 1360px;
        -webkit-mask-size: auto 1360px;
        mask-size: auto 1360px;
    }
    @include media-mobile {
        height: 384px;
        -webkit-mask-size: auto 490px;
        mask-size: auto 490px;
        // -webkit-mask-image: url("../assets/svg/4-mobile-center.svg");
        // mask-image: url("../assets/svg/4-mobile-center.svg");
        // mask-position: center top;
    }
}
.why-recommend__parallax-bg {
    position: absolute;
    width: 100%;
    height: max(850px, (100vw/2) + 180px);
    background: url("../assets/img/implants.jpg") no-repeat right top;
    background-size: auto max(850px, (100vw/2) + 180px);
    @include media-tablet {
        height: max(750px, 50vw + 180px);
        background-size: auto max(600px, 50vw + 180px);
        top: 100px;
    }
    @include media-desktop {
        // background-size: auto max(1080px, (100vw/2) + 180px);
    }
    @include media-desktop-max {
        height: 1285px;
        background-size: auto 1285px;
    }
    @include media-mobile {
        position: relative;
        height: 384px;
        top: -15px;
        order: 1;
        // background: url("../assets/img/implants.jpg") no-repeat center top;

        background-size: auto 384px;
    }
}
.why-recommend__parallax {
    position: absolute;
    width: 100%;
    height: max(850px, (100vw/2) + 180px);
    opacity: 0.74;
    background: url("../assets/svg/4-2.svg") no-repeat right top;
    background-size: auto max(850px, (100vw/2) + 180px);
    @include media-tablet {
        background-size: auto max(750px, 50vw + 180px);
    }
    @include media-desktop {
        height: max(1080px, (100vw/2) + 180px);
        background-size: auto max(1080px, (100vw/2) + 180px);
    }
    @include media-desktop-max {
        height: 1360px;
        background-size: auto 1360px;
    }
    @include media-mobile {
        height: 384px;
        // background: url("../assets/svg/4-mobile-center.svg") no-repeat center top;

        background-size: auto 490px;
    }
}
.why-recommend__parallax-bottom {
    position: absolute;
    width: 100%;
    height: 300px;
    top: max(850px, (100vw/2) + 180px);
    background-color: $color-parallax-bg2;
    z-index: 2;
    @include media-tablet {
        top: max(750px, 50vw + 180px);
    }
    @include media-desktop-max {
        top: 1360px;
    }
}
.why-recommend__text-block {
    position: relative;
    padding-top: 51px;
    @include flexColumn(space-between, flex-start);
    gap: 85px;
    z-index: 2;
    @include media-tablet-touch {
        height: 610px;
        padding-bottom: 40px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @include media-desktop {
        padding: 95px 0 110px 0;
    }
    @include media-mobile {
        padding-top: 25px;
        gap: 34px;
        height: auto;
        padding-bottom: 0;
        overflow: unset;
        scrollbar-width: auto;
    }
}
.why-recommend__text-container {
    @include flexColumn(space-between, flex-start);
    gap: 50px;
    @include media-desktop {
        max-height: 365px;
        flex-wrap: wrap;
    }
    @include media-mobile {
        gap: 22px;
    }
}
.why-recommend__title {
    max-width: 385px;
}
.why-recommend__text {
    max-width: 250px;
    border-left: 3px solid $color-primary;
    padding-left: 10px;
    @include font(24px, 300, $color-dark);
    @include media-mobile {
        font-size: 18px;
        max-width: 345px;
    }
}
