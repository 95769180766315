* {
    box-sizing: border-box;
}
:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    @include media-desktop-max {
        max-width: 2560px;
        margin: 0 auto;
    }
}
.body-no-scroll {
    @include media-touch {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}
