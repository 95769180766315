.burger-btn {
    @include flexColumn(flex-start, center);
    gap: 8px;
    width: 100%;
    height: 60px;
    padding: 0;
    border: 0;
    cursor: pointer;
    background-color: $color-primary;
    transition: 0.2s;
    &:hover {
        background-color: $color-primary-hover;
        .burger-btn__icon {
            &::before {
                width: 100%;
                left: 0;
            }
            &::after {
                width: 100%;
            }
        }
    }
    @include media-tablet {
        order: 3;
        width: 90px;
        height: 60px;
    }
    @include media-mobile-xs {
        width: 75px;
    }
}
.burger-btn__icon {
    &::before {
        content: "";
        display: block;
        position: relative;
        top: -5px;
        left: 50%;
        width: 50%;
        height: 2px;
        border-radius: 3px;
        background-color: $color-dark;
        transition: 0.2s;
    }
    position: relative;
    width: 24px;
    height: 2px;
    margin-top: 20px;
    border-radius: 3px;
    background-color: $color-dark;
    transition: 0.2s;

    &::after {
        content: "";
        position: relative;
        display: block;
        top: 3px;
        left: 0;
        width: 50%;
        height: 2px;
        border-radius: 3px;
        background-color: $color-dark;
        transition: 0.2s;
    }
    &.open {
        top: 7px;
        background-color: $color-bg-figure2;
        transform: rotate(45deg);

        &::before {
            width: 24px;
            top: 0;
            left: 0;
            transform: rotate(90deg);
            background-color: $color-bg-figure2;
        }
        &::after {
            width: 24px;
            top: -2px;
            background-color: $color-bg-figure2;
        }
    }
}
.burger-btn__title {
    transition: 0.2s;
    @include font(15px, 400, $color-dark, normal);
}
