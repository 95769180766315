// fonts

// colors
$color-primary: #dec054;
$color-primary-hover: #d7b041;
$color-primary-active: #a68528;
$color-secondary: #ebebeb;
$color-dark: #3e3d3d;
$color-dark-light: #999999;
$color-light: #ffffff;
$color-light-opacity-05: #ffffff80;
$color-accent: #0085ff;
$color-accent-opacity-08: rgba(0, 133, 255, 0.8);
$color-bg-dark: #f6f6f6;
$color-parallax-bg: #fbfbfb;
$color-parallax-bg2: #fdfdfd;
$color-bg-figure2: #d9d9d9;
