.baSlider {
    position: relative;
    overflow: hidden;
}
.baSlider__img-wrapper {
    overflow: hidden;
    height: 100%;
    &.before {
        position: absolute;
        width: 100%;
        top: 0;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 2px;
            height: 100%;
            background-color: $color-light;
        }
    }
}
.baSlider__img {
    object-fit: cover;
    display: block;
    max-width: 600px;
    &.after {
        // max-width: 100%;
        width: 100%;
    }
}
.baSlider__spinner-container {
    position: absolute;
    @include flexColumn(center, center);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $color-light;
    opacity: 0;
    z-index: -1;
    &.loading {
        opacity: 1;
        border: 1px solid $color-secondary;
        transition: unset;
        z-index: unset;
    }
}
.baSlider__spinner-container-svg {
    position: absolute;
    height: 100px;
}
.baSlider__slider {
    position: absolute;
    @include flexRow(center, center);
    display: none;
    width: 34px;
    height: 30px;
    padding: 4px;
    cursor: pointer;
    border-radius: 9px;
    background-color: $color-light;
    transition: 0.2s;

    &:hover {
        width: 60px;
        transition: 0.2s;
        .baSlider__slider-inner {
            background-color: $color-primary;
            box-shadow: 0 0 4px 0 $color-primary;
        }
        .baSlider__slider-arrow-svg.left {
            display: none;
        }
        .baSlider__slider-arrow-svg-long {
            // animation-duration: 1s;
            // animation-direction: normal;
            // animation-fill-mode: forwards;
            // animation-name: sliderAnimation;
            animation: 0.3s sliderAnimation normal forwards;
        }
    }
    &.active {
        width: 44px;
        transition: unset;
        transition:
            background-color 0.2s,
            width 0.2s;
        background-color: $color-primary;
        cursor: grabbing;
        .baSlider__slider-inner {
            background-color: $color-primary;
            box-shadow: unset;
        }
        .baSlider__slider-arrow-svg-long {
            display: none;
        }
        .baSlider__slider-arrow-svg {
            color: $color-light;
        }
        .baSlider__slider-arrow-svg.left {
            display: block;
        }
    }
}

.baSlider__slider-inner {
    @include flexRow(space-between, center);
    width: 100%;
    height: 100%;
    padding: 0 2px;
    border-radius: 9px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-light;
    transition: 0.4s;
}
.baSlider__slider-arrow {
    width: 7px;
    height: 12px;
    color: $color-bg-figure2;
    &.right {
        transform: rotate(180deg);
    }
}
.baSlider__slider-arrow-svg {
    transition: 0.2s;
    position: absolute;
}
.baSlider__slider-arrow-svg-long {
    position: absolute;
    color: $color-light;
    opacity: 0;
}

@keyframes sliderAnimation {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
