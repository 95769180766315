@import "reset.css";
@import "base/typography";
@import "abstracts/variables";
@import "abstracts/placeholders";
@import "abstracts/mixins";
@import "base/base";
@import "components/button";
@import "components/ba-slider";
@import "layouts/header";
@import "layouts/main";
@import "layouts/footer";
@import "components/hero";
@import "components/divider";
@import "components/our-patients";
@import "components/why-recommend";
@import "components/stages";
@import "components/pricing-approach";
@import "components/price";
@import "components/our-team";
@import "components/review-modal";
@import "components/before-after";
@import "components/faq";
@import "components/contacts";
@import "components/burger-button";
@import "components/phone-button";
@import "components/burger-menu";
