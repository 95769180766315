.main {
    // height: 200vh;
    margin-left: 90px;
    @include media-desktop-max {
        max-width: 2560px;
    }
    @include media-tablet {
        margin-left: 0;
        margin-top: 60px;
    }
}
.section {
    padding-left: 5%;
    scroll-margin-top: 0;
    // @include media-desktop {
    //     scroll-margin-top: 0;
    // }
    @include media-tablet {
        scroll-margin-top: 60px;
    }
}
.article {
    padding-left: 5%;
    scroll-margin-top: 60px;
    @include media-desktop {
        scroll-margin-top: 0;
    }
}

.section-title {
    @include font(34px, 500, $color-dark);
    @include media-mobile {
        font-size: 30px;
    }
}
.article-title {
    @include font(34px, 500, $color-dark);
    @include media-mobile {
        font-size: 30px;
    }
}
.middle-block-wrapper {
    position: relative;
    width: 100%;
    // height: 4000px;
    // background-color: $color-parallax-bg;
    z-index: 2;
    // background: url("../assets/img/implants.jpg") no-repeat right top;
    // background-size: auto max(850px, (100vw/2) + 180px);
    // @include media-desktop {
    //     background-size: auto max(1080px, (100vw/2) + 180px);
    // }
    @include media-mobile {
        @include flexColumn;
    }
}
.stages-price-team-wrapper {
    position: relative;
    // height: 2000px;
    padding: 13% 0 0;
    z-index: 2;
    @include media-tablet {
        padding: 16% 0 0;
    }
    @include media-desktop {
        padding: 10% 0 0;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: url("../assets/svg/stages-bg.svg") no-repeat -400px top/min(2200px, 230%);
        @include media-tablet {
            background: url("../assets/svg/stages-bg.svg") no-repeat -400px top/min(2200px, 270%);
        }
        @include media-desktop {
            background: url("../assets/svg/stages-bg.svg") no-repeat center top/ 150%;
        }
        @include media-mobile {
            background: url("../assets/svg/stages-bg.svg") no-repeat -400px top/min(2200px, 480%);
        }
        @include media-mobile-xs {
            background: url("../assets/svg/stages-bg.svg") no-repeat -400px top/min(2200px, 555%);
        }
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 30%;
        right: 0;
        z-index: -2;
        background: url("../assets/svg/bg-figure-2.svg") no-repeat -400px top/min(1800px, 230%);
        @include media-desktop {
            background: url("../assets/svg/bg-figure-2.svg") no-repeat center top/ 130%;
        }
        @include media-mobile {
            background: url("../assets/svg/bg-figure-2.svg") no-repeat center top/ 2800px;
        }
    }
    @include media-mobile {
        top: -50px;
        order: 2;
        padding: 16% 0 0;
    }
}
