.contacts {
    position: relative;
    padding-bottom: 37px;
    z-index: 2;
    @include media-desktop {
        padding-bottom: 57px;
    }
    @include media-desktop-b {
        @include flexColumn(flex-start, center);
        padding-left: 0;
    }
    @include media-mobile {
        padding: 0 15px 37px;
    }
}
.contacts__title {
    margin: 90px 0 30px;
}
.contacts__addr-container {
    padding-bottom: 37px;
    @include flexRow(flex-start, flex-start);
    gap: 30px;
    @include font(16px, 300, $color-dark);
    @include media-mobile {
        flex-direction: column;
    }
}
.contacts__addr {
    @include flexRow(flex-start, center);
    gap: 10px;
    transition: 0.2s;
    &:hover {
        cursor: pointer;
        .contacts__addr-icon {
            color: $color-accent;
        }
    }
    &.active {
        color: $color-accent;
        .contacts__addr-icon {
            color: $color-accent;
        }
    }
}

.contacts__addr-icon {
    min-width: 24px;
    color: $color-dark;
    transition: 0.2s;
}
.contacts__middle-block-wrapper {
    @include flexRow;
    gap: 30px;
    @include media-mobile {
        flex-direction: column;
    }
}
.contacts__photos-container {
    @include flexColumn;
    gap: 50px;
    // max-width: calc(95% - 80px);
    @include media-desktop {
        @include flexRow;
        gap: 4px;
    }
    @include media-mobile {
        max-width: 100vw;
        margin: 0 -15px;
    }
}
.contacts__main-photo-container {
    position: relative;
    @include flexRow;
    gap: 34px;
    @include media-tablet {
        max-width: 95%;
    }
    @include media-mobile {
        justify-content: center;
        max-width: unset;
    }
}
.contacts__main-photo-img {
    position: absolute;
    max-width: 100%;
    max-height: 412px;
    transition: 0.1s;
    &.fade-out {
        opacity: 0;
        pointer-events: none;
    }

    @include media-desktop {
        max-height: 412px;
    }
}
.contacts__main-photo-spinner-container {
    position: relative;
    @include flexColumn(center, center);
}
.contacts__main-photo-spinner {
    max-width: 100%;
    max-height: 412px;
    opacity: 0;
    position: relative;
    transition: 0.3s;
    @include media-desktop {
        max-height: 412px;
    }
    &.loading {
        opacity: 1;
        border: 1px solid $color-secondary;
        transition: unset;
    }
}
.contacts__main-photo-spinner-svg {
    position: absolute;
    display: none;

    @include media-mobile {
        height: 200px;
    }
    @include media-mobile-xs {
        height: 130px;
    }
    &.loading {
        display: block;
    }
}
.contacts__main-photo-desc {
    position: absolute;
    // bottom: 128px;
    bottom: -36px;
    @include font(14px, 300, $color-dark-light);
    transition: 0.1s;
    &.fade-out {
        opacity: 0;
        pointer-events: none;
    }
    @include media-desktop {
        // bottom: 26px;
        bottom: -36px;
    }
    @include media-mobile {
        left: 0;
        // bottom: 250px;
        bottom: -36px;

        padding-left: 15px;
    }
}
.contacts__carousel-photos {
    @include flexRow;
    gap: 5px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    @include media-desktop {
        @include flexColumn(space-between);
        height: 100%;
        gap: unset;
    }
    @include media-mobile {
        overflow: auto;
        padding-bottom: 5px;
    }
}
.contacts__carousel-photo {
    position: relative;
    max-width: 133px;
    max-height: 80px;

    &.active {
        .contacts__carousel-photo-img {
            box-shadow: 0px 0px 9px 0px rgba(0, 133, 255, 0.25);
            filter: none;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: 0.2s imageActiveAnimation normal forwards;
        }
    }
}
.contacts__carousel-photo-spinner {
    position: absolute;
    @include flexColumn(center);
    display: none;
    width: 133px;
    height: 80px;
    top: 0;
    left: 0;
    // background-color: #ebebeb;
    background-color: $color-light;
    &.loading {
        display: flex;
        border: 1px solid $color-secondary;
    }
}
.contacts__carousel-photo-spinner-svg {
    height: 65px;
}
.contacts__carousel-photo-img {
    position: relative;
    width: 133px;
    height: 80px;
    cursor: pointer;
    filter: brightness(0.9);
    &:hover {
        box-shadow: 0px 0px 9px 0px rgba(0, 133, 255, 0.25);
        filter: none;
    }
}
.contacts__maps-links-container {
    @include flexColumn(space-between, center);
    &.vertical {
        display: none;
        @include media-desktop {
            display: flex;
        }
        @include media-mobile {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }
    }
    &.horizontal {
        @include media-desktop {
            display: none;
        }
        @include media-mobile {
            display: none;
        }
    }
}
.contacts__map-link {
    @include flexColumn(flex-start, center);
    transition: 0.2s;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        filter: sepia(1);
    }
}
.contacts__map-link-icon {
    padding-bottom: 7px;
}
.contacts__map-link-text {
    max-width: 80px;
    text-align: center;
    @include font(16px, 500, $color-dark-light);
}

@keyframes imageActiveAnimation {
    0% {
        backdrop-filter: blur(0);
    }

    100% {
        background: $color-light-opacity-05;
        backdrop-filter: blur(2px);
    }
}
