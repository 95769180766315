.pricing-approach {
    position: relative;
    z-index: 2;
    @include media-mobile {
        padding: 0 15px;
    }
}
.pricing-approach__title {
    padding-top: 50px;
    margin-bottom: 18px;
}
.pricing-approach__title-desc {
    max-width: 756px;
    margin-bottom: 76px;
    @include font(24px, 300, $color-dark, normal);
    @include media-mobile {
        margin-bottom: 43px;
    }
}
.pricing-approach__ideas {
    position: relative;
    @include flexColumn(space-between, flex-start);
    gap: 14px;
    left: -5%;
    @include media-mobile {
        max-height: 250px;
        left: 0;
        gap: 26px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.pricing-idea {
    @include flexRow(flex-start, center);
}
.pricing-idea__divider {
    width: 5%;
    height: 1px;
    background-color: $color-bg-figure2;
    @include media-mobile {
        display: none;
    }
}
.pricing-idea__text {
    max-width: 622px;
    padding-left: 18px;
    @include font(16px, 400, $color-dark, normal);
    border-left: 3px solid $color-bg-figure2;
}
.pricing-idea__divider-right {
    width: 375px;
    height: 1px;
    background-color: $color-bg-figure2;
    @include media-mobile {
        display: none;
    }
}
.pricing-idea__img {
    @include media-mobile {
        display: none;
    }
}
.pricing-idea__imgages-mobile {
    display: none;
    @include media-mobile {
        margin-bottom: 35px;
        @include flexRow(space-between, center);
    }
}
