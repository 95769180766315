.before-after__review {
}
.review {
    position: absolute;
    @include flexColumn(center, flex-start);
    width: 90vw;
    max-width: calc(100% - 10px);
    height: 475px;
    top: 130px;
    right: 0;
    border-radius: 90px 0 0 90px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    box-shadow: 0 0 30px 0 rgba(0, 133, 255, 0.2);
    background: rgba(255, 255, 255, 0.8);
    transition: 0.3s;
    transform: translateX(110%);
    &.active {
        transform: translateX(0);
    }
    @include media-desktop-max {
        width: 95%;
    }
    @include media-tablet {
        width: 100vw;
        top: 90px;
        position: fixed;
    }
    @include media-mobile {
        width: 100%;
        max-width: unset;
        height: calc(100% - 60px);
        top: 60px;
        padding: 10px;
        gap: 0;
        border-radius: 0;
    }
}
.review__photo {
    position: absolute;
    width: 123px;
    height: 123px;
    top: 28px;
    left: 28px;
    border: 2px solid $color-bg-figure2;
    border-radius: 100px;
    // box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    @include media-mobile {
        position: static;
    }
}
.review__container {
    @include flexColumn;
    gap: 16px;
    max-width: 687px;
    width: 100%;
    margin-left: 210px;
    overflow: auto;
    @include media-tablet {
        max-width: calc(100% - 220px);
    }
    @include media-mobile {
        width: 100%;
        max-width: 400px;
        margin-left: 0;
        margin-bottom: 30px;
    }
}
.review__title {
    @include font(34px, 200, $color-dark, normal);
}
.review__data {
}
.review__data-title {
    @include font(16px, 600, $color-accent, normal);
}
.review__data-desc {
    @include font(16px, 200, $color-dark, normal);
}
.review__review {
    @include font(22px, 300, $color-dark, normal);
    @include media-mobile {
        font-size: 20px;
    }
}
.review__close-btn {
    padding: 0;
    // padding-top: 33px;
    margin-top: 32px;
    @include flexRow(flex-start, baseline);
    gap: 15px;
    @include font(16px, 300, $color-primary, normal);
    border: 0;
    color: $color-accent;
    background-color: unset;
    cursor: pointer;
    transition: 0.2s;

    @include media-mobile {
        position: fixed;
        margin-top: 20px;
        bottom: 15px;
        // padding-left: 26px;
    }
}
.review__close-text {
}
.review__close-btn-svg {
    transform: rotate(180deg);
    @include media-tablet {
        transform: rotate(180deg);
    }
    @include media-mobile-xs {
        width: 140px;
    }
}
