.footer {
    position: relative;
    @include flexRow(space-between, center);
    height: 80px;
    margin-left: 90px;
    padding: 0 5%;
    background-color: $color-secondary;
    z-index: 2;
    @include media-tablet {
        margin-left: 0;
    }
    @include media-mobile {
        justify-content: center;
    }
}

.footer__link-to-main {
    height: 39px;
    @include media-mobile {
        display: none;
    }
}
.footer__logo-footer-svg {
    color: $color-light;
    transition: 0.3s;
    &:hover {
        color: $color-primary;
    }
}

.footer__license {
    @include flexRow(center, center);
    flex-wrap: wrap;
    column-gap: 10px;
    @include font(16px, 300, $color-dark-light);
}
