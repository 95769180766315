.stages {
    // position: relative;
    // height: 1000px;
    padding: 0 5%;
    // z-index: 2;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     z-index: -1;
    //     background: url("../assets/svg/stages-bg.svg") no-repeat center top/ 130%;
    // }
    @include media-desktop-max {
        background-size: auto max(1080px, (100vw/2) + 80px);
        padding-right: 10%;
    }
    @include media-mobile {
        padding: 0 15px;
    }
}
.stages-title {
    max-width: 687px;
    margin-bottom: 45px;
    line-height: normal;
    @include media-mobile {
        margin-bottom: 20px;
    }
}
.stages__wrapper {
    @include flexRow(space-between, flex-start);
    flex-wrap: wrap;
    max-width: 90%;
    gap: 30px;
    @include media-tablet {
        @include flexColumn;
        flex-wrap: unset;
        height: 525px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @include media-desktop {
        @include flexRow(space-between, flex-start);
        gap: unset;
    }
    @include media-mobile {
        height: 390px;
    }
}

.stages__stage {
}
.stage {
    width: 240px;
}
.stage__title {
    @include flexRow(flex-start, center);
    padding-bottom: 12px;
    border-bottom: 2px solid $color-primary-active;
}
.stage__title-number {
    @include font(100px, 800, $color-primary-active);
}
.stage__title-text {
    padding-top: 10px;
    writing-mode: vertical-lr;
    @include font(34px, 500, $color-primary-active);
}

.stage__desc {
    padding-top: 28px;
    @include font(16px, 300, $color-dark, normal);
    @include media-tablet {
        width: 611px;
    }
    @include media-mobile {
        width: 80vw;
    }
}
